import { Field, Form, Formik } from 'formik'
import { FC, useState } from 'react'
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import SuperSelect from '../../components/SuperSelect'
import { useQuery } from 'react-query'
import { exportOrders } from '../../requests/requests'
import { useAuth } from '../../modules/auth'
import { useGetMyClients } from '../../hooks/useGetMyClients'
import { ExportOrderItem } from '../../models/exportOrders'
import { configStaleTime } from '../../constants'
import { CSVLink } from 'react-csv'

const ExportOrders: FC = () => {
  const intl = useIntl()
  const { auth } = useAuth()

  const [filteredSageClient, setFilteredSageClient] = useState('');
  interface ExportOrderFilters {
    startDate: string,
    endDate: string,
    sageClient: string
  }
  const [submitted, setSubmitted] = useState({} as ExportOrderFilters);
  const [submitting, setSubmitting] = useState(0);
  const [usedFilter, setUseFilter] = useState(false);
  const [changedFilters, setChangedFilters] = useState(false);
  const [selectRef, setSelectRef] = useState();

  const { myClientsOptions } = useGetMyClients();

  const maxDate = () => {
    const d = new Date()
    return d.toISOString().slice(0, 10)
  }

  const { data, refetch } = useQuery<ExportOrderItem[]>(
    ['exportOrders'],
    async () => {
      const data = await exportOrders(submitted.startDate ? submitted.startDate : undefined, submitted.endDate ? submitted.endDate : undefined, submitted.sageClient ? submitted.sageClient : undefined);
      return data
    },
    { staleTime: configStaleTime, enabled: !!auth && usedFilter }
  )

  let dataForCsv = [] as any[]
  if (data) {
    data.forEach((el) => {
      if (el.lines) {
        el.lines.forEach((line) => {
          dataForCsv.push({
            OrderNumber: el.guidId,
            ProductID: line.code,
            ExpeditionDate: el.expeditionDate,
            Client: el.detail.clientBusinessName,
            Distributor: el.customer.businessName,
            ShippingAddressCode: el.detail.shippingAddressSageCode,
            ShippingAddressDescription: el.detail.shippingAddressDescription,
            ProductName: line.description,
            Quantity: line.quantity,
            PackageUnit: line.packageUnit.startsWith('c') ? 'UN' : line.packageUnit,
            KgServed: line.stockRatio,
            /*KgServed: getProductFormat(
              line.packageUnit,
              line.stockRatio,
              intl.formatMessage({id: 'GENERAL.OF'}),
              ''
            ),*/
            SalesUnit: line.salesUnit.startsWith('c') ? 'UN' : line.salesUnit,
            'P.O. Order': el.detail.customerExternalReference
          })
        })
      }
    })
  }

  const clientOptions = myClientsOptions()

  return (
    <>
      <section className='card card-flush p-8 flex-row-fluid'>
        <Container className='w-100' fluid={true}>
          <Formik initialValues={{
            startDate: '',
            endDate: '',
            sageClient: ''
          } as ExportOrderFilters} onSubmit={(x) => {
            x.sageClient = filteredSageClient
            setSubmitting(1)
            setSubmitted(x)
            setTimeout(() => {
              refetch()
              setSubmitting(0)
            }, 100)
          }}>
            {({ handleChange }) => (
              <Form placeholder={''} className='row export-orders-wrapper'>
                <Col lg={12}>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h2>{intl.formatMessage({ id: 'MENU.EXPORT_ORDERS' })}</h2>
                  </div>
                </Col>
                <Col lg={12} className='mb-8'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <span className="text-gray-400 pt-1 fw-semibold fs-6">{intl.formatMessage({ id: 'EXPORT_ORDERS.HELP_FILTER' })}.</span>
                  </div>
                </Col>
                <Col lg={12}>
                  <h3 style={{ fontSize: "14px" }} className='mb-5'>{intl.formatMessage({ id: 'EXPORT_ORDERS.FILTER' })}</h3>
                </Col>
                <Col>
                  <Row className='align-items-end'>
                    <Col lg={3} xl={2}>
                      <label style={{ color: '#99A1B7' }} htmlFor='startDate' className='mb-3'>
                        {intl.formatMessage({ id: 'EXPORT_ORDERS.SELECT_START_DATE' })}
                      </label>
                      <Field
                        className='form-control'
                        type='date'
                        name='startDate'
                        id='startDate'
                        onChange={(e) => {
                          setChangedFilters(true)
                          handleChange(e)
                        }}
                      />
                    </Col>
                    <Col lg={3} xl={2} className='mt-3 mt-lg-0'>
                      <label className='mb-3' style={{ color: '#99A1B7' }} htmlFor='endDate'>
                        {intl.formatMessage({ id: 'EXPORT_ORDERS.SELECT_END_DATE' })}
                      </label>
                      <Field
                        className='form-control'
                        type='date'
                        name='endDate'
                        id='endDate'
                        max={maxDate()}
                        onChange={(e) => {
                          setChangedFilters(true)
                          handleChange(e)
                        }}
                      />
                    </Col>
                    <Col lg={4} xl={3} className='mt-3 mt-lg-0'>
                      <label style={{ color: '#99A1B7' }} className='d-block'>
                        {Object.keys(clientOptions).length > 0 ?
                          <SuperSelect
                            options={clientOptions}
                            onChange={(x, ref) => {
                              if (x) {
                                setFilteredSageClient(x.value)
                                setChangedFilters(true)
                                setSelectRef(ref)
                              }
                            }}
                            placeholder={intl.formatMessage({ id: 'GENERAL_SELECT' })}
                            
                          ></SuperSelect> : <><Spinner animation='border' /></>}
                      </label>
                    </Col>
                    <Col className='mt-5 mt-lg-0'>
                      <Button type='reset' className={`me-3`} variant='secondary' onClick={() => {
                        setFilteredSageClient('')
                        if (selectRef) {
                          (selectRef as any).current.clearValue();
                        }
                      }}>
                        {intl.formatMessage({ id: 'EXPORT_ORDERS.RESET' })}
                      </Button>
                      <Button type='submit' className={`me-3 ${submitting && submitting === 1 ? 'disabled' : ''}`} onClick={() => {
                        setUseFilter(true)
                        setChangedFilters(false)
                      }}>
                        {intl.formatMessage({ id: 'EXPORT_ORDERS.FILTER' })}
                        {submitting && submitting === 1 ? <Spinner animation={'border'} size='sm' className='ms-3' /> : ''}
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} className='my-8'>
                  {!changedFilters && usedFilter && <span className='text-primary fw-bold'><FormattedMessage id="EXPORT_ORDERS.FOUND_RECORDS" values={{ total: dataForCsv.length }}></FormattedMessage></span>}
                </Col>
                <Col lg={12}>
                  <Row className='align-items-center'>
                    <Col lg={12} className={`mb-5`}>
                      <span className="text-gray-400 pt-1 fw-semibold fs-6">{intl.formatMessage({ id: 'EXPORT_ORDERS.HELP_DOWNLOAD' })}.</span>
                    </Col>
                    <Col>
                      {!changedFilters && dataForCsv.length > 0 ? <><CSVLink
                        data={dataForCsv}
                        target='_blank'
                        separator={';'}
                        filename={`exported-orders${submitted.sageClient ? '-' + submitted.sageClient : ''}.csv`}
                      >
                        <Button type='button' className={`${dataForCsv.length > 0 ? '' : 'disabled'}`} onClick={() => {
                          setSubmitting(2)
                          setTimeout(() => { setSubmitting(0) }, 500)
                        }}>
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className='me-3 mb-1'>
                            <rect width="20" height="20" transform="matrix(-1 0 0 1 20 0)" fill="url(#pattern0_601_404)"/>
                            <defs>
                              <pattern id="pattern0_601_404" patternContentUnits="objectBoundingBox" width="1" height="1">
                                <use xlinkHref="#image0_601_404" transform="scale(0.0111111)"/>
                              </pattern>
                              <image id="image0_601_404" width="90" height="90" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAACV0lEQVR4nO3cW27TQBhAYadSy0UsoDvkshECD1CJIpWn7oEHxAYQArZBC0iIWwU8HmRlhKzUTpzwe0pmzpHyklgT9+vUlsdWm8bMzMzMOgEHwAPgHDgD5u173W0soAS73DxibOuUZvJyH7rbWEDpcLHc+4ixbf2h4353G4s7Gc7TzPZkaGZmZrbb4VpHNui5ax15oM9d68gDfeZaRx7ouWsdeaAPXOswMzOzfAF7ek8LfAM4Br6l15P2PdHjoY96LlgeCR0P/bEH+pPQ8dC9CS30bsZAV71fxYXQQhcVzmihiwpntNBFhTNa6KLCGS10UeGM/r+hgb2q78qwuGPyDPgJfAYeAteioNux0l2YdvzfwClwq6ktFsjLvRjC3gQ6IbdjLXfa1BQwAy4G7Hqxx0KvQCbN7LoOI8CPIbw+7DHQa5BJv9xZU1PA4xUgl7DXQY9AbjtqdqHIPzvGwfzFXgW96VhBh77ZzjxTwQZAKz7PhgzcBE6AX+l1EvpsyZTPVDAeapvPwpDTvj7tGf84YuxVz1SE/a8MxmFvUxhy2s+vPd/xZacuf1k80f88EPklcD14H6d1yAEdjB2OXBR0EPYkyMVB/yP2ZMhFQm+JPSlysdAbYk+OXDT0SOwsyMVDr8HOhlwF9AB2VuRqoJewsyNXBd25XA+7rN6kqqCvMqEzJXSmhM6U0JkSOlNCFwQ99LCLwfdI6NeKDvYqEvre8PdU353oNYZ31ZNe7g2wHwadsA+Btz1fVjPyYShyB3sfuNselyo9QV6kn/12+Ew2MzMzMzNrrqg/bFh+fjo5fd0AAAAASUVORK5CYII="/>
                            </defs>
                          </svg>
                          {intl.formatMessage({ id: 'EXPORT_ORDERS.DOWNLOAD_EXPORT' })}
                          {submitting && submitting === 2 ? <Spinner animation={'border'} size='sm' className='ms-3' /> : ''}
                        </Button>
                      </CSVLink></> : <Button type='button' className={`${usedFilter && dataForCsv.length > 0 ? '' : 'disabled'} ${changedFilters ? 'disabled' : ''}`} onClick={() => {
                      }}>
                        {intl.formatMessage({ id: 'EXPORT_ORDERS.DOWNLOAD_EXPORT' })}
                        {submitting && submitting === 2 ? <Spinner animation={'border'} size='sm' className='ms-3' /> : ''}
                      </Button>}
                    </Col>
                  </Row>
                </Col>
              </Form>
            )}
          </Formik>
        </Container>
      </section>
    </>
  )
}

export { ExportOrders }
